<template>
  <Modal
    ref="modalShippingPrice"
    id-custom="modal-shipping-price"
    :use-icon-close="true"
    :header="true"
    :close-custom="true"
    @close="$emit('close')"
  >
    <template #header>
      <span>Jenis Pengiriman</span>
    </template>
    <template>
      <ModalProductPromo
        v-if="showModalPromo"
        @close="closeModalPromo"
      />
      <section
        v-if="productListPromoExist"
        class="promo-product"
      >
        <img
          id="promo-product-icon"
          src="/images/tariff/promo_aplikasi_gray.svg"
          alt="promo icon"
        >
        <article>
          <b>Promo Flat Ongkir!</b>
          Berapapun beratnya, ongkirnya satu harga & bebas volume se-JABODETABEKCIK
        </article>
        <img
          id="promo-product-info"
          width="20"
          height="20"
          src="/images/chevron-right-gray.svg"
          alt="arrow right"
          @click="initModalPromo"
        >
      </section>
      <section
        v-for="(valueData, index) in data"
        :key="`product-${index}`"
        class="shipping-product-wrapper"
      >
        <div :class="['shipping-product', {'shipping-product__inactive': !valueData.isActive}]">
          <div class="shipping-product-title">
            <div
              class="shipping-product-title__ribbon"
              :style="setStyleRibbon(valueData)"
            >
              <div class="shipping-product-title__ribbon-icon">
                <img
                  v-if="isShowRibbonIcon(valueData)"
                  :src="valueData.displayStyle.icon_ribbon_asset"
                  alt="ribbon Lion Parcel"
                >
                <span
                  v-else
                  :style="{color: valueData.displayStyle.text_color_ribbon}"
                >
                  {{ valueData.displayStyle.text_ribbon }}
                </span>
              </div>
            </div>
            <div class="shipping-product-title__question">
              <img
                :src="`/images/tariff/info.svg`"
                alt="info product lion parcel.svg"
                width="14px"
                :style="!valueData.isActive ? {'z-index': '-1'} : {}"
              >
              <span
                v-if="valueData.displayStyle.text_service_title ||
                  valueData.displayStyle.text_service_title"
                class="shipping-product-title__question__desc"
              >
                <span class="shipping-product-title__question__desc__text">
                  {{
                    valueData.displayStyle.text_tooltip ||
                    valueData.displayStyle.text_service_title
                  }}
                </span>
              </span>
            </div>
          </div>
          <div
            :class="['shipping-product__info',
                     {'shipping-product__info-inactive': !valueData.isActive}
            ]"
            @click="clickProduct(valueData.name, valueData.isActive, index)"
          >
            <img
              :src="valueData.displayStyle.root_service_asset"
              :alt="valueData.name"
            >
            <div>
              <template v-if="hasDiscount(valueData)">
                <span class="shipping-product__price">{{ valueData.priceBase.display }}</span>
                <div  class="shipping-product__display-discount">
                  <span>{{ valueData.price.display }}</span>
                  <span>{{ valueData.discountPercentage }}</span>
                </div>
              </template>
              <template v-else>
                <span class="shipping-product__price">{{ valueData.price.display }}</span>
              </template>
              <span class="shipping-product__estimate">
                {{ valueData.estimate ? valueData.estimate : valueData.displayStyle.text_estimation }}
              </span>
            </div>
          </div>
          <div
            v-if="recommend === valueData.name"
            class="shipping-product__recommended"
          >
            <img
              src="/images/ics_f_love.svg"
              alt="icon recommend"
            >
            <span>Rekomendasi</span>
          </div>
        </div>
        <div
          v-if="valueData.isChoose"
          class="choose-product"
        />
      </section>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/new-modal/Modal.vue';
import { ProductList } from '@/app/constant/product';
import { ProductName } from '../../../app/enum';
import ModalProductPromo from './ModalProductPromo.vue';

export default {
  name: 'ModalShippingPrice',
  components: { Modal, ModalProductPromo },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    recommend: {
      type: String,
      default: ProductName.BOSSPACK.toLowerCase(),
    },
  },
  data() {
    return {
      showModalPromo: false,
      classModelContent: 'modal-lp__content__body',
    };
  },
  computed: {
    productListPromoExist() {
      return ProductList
        && ProductList.length > 0
        && ProductList.every(val => val.promo && val.promo.length > 0);
    },
  },
  methods: {
    hasDiscount(val) {
      return val.discountPercentage && val.discountPercentage !== '';
    },
    clickProduct(productName, isActive, index) {
      if (isActive) {
        this.$emit('click-product', productName, index);
      }
    },
    isShowRibbonIcon(val) {
      return val.displayStyle.show_ribbon && (val.displayStyle.icon_ribbon_asset !== '' && val.displayStyle.icon_ribbon_asset);
    },
    setStyleRibbon(val) {
      const style = { borderColor: 'transparent transparent transparent transparent' };
      if (val.displayStyle.show_ribbon) {
        style.borderColor = `${val.displayStyle.background_color_ribbon} transparent transparent transparent`;
      }
      return style;
    },
    setParentModalStyle(isShowModalPromo) {
      const el = this.$refs.modalShippingPrice.$el.getElementsByClassName(this.classModelContent);
      if (el && el.length > 0) {
        if (isShowModalPromo) {
          el[0].style = 'overflow-y: auto; max-height: 400px;';
        } else {
          el[0].removeAttribute('style');
        }
      }
    },
    initModalPromo() {
      this.setParentModalStyle(true);
      this.showModalPromo = true;
    },
    closeModalPromo() {
      this.setParentModalStyle(false);
      this.showModalPromo = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/style';

.choose-product {
  border: 0.25px solid $color-gray-pensive-3;
  margin-bottom: 1rem;
}

.shipping-product-wrapper {
  > .shipping-product:hover {
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  }
}

@mixin inActive() {
  cursor: not-allowed !important;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  @content;
}

.shipping-product {
  display: flex;
  position: relative;
  border-radius: 10px;
  border: 1px solid $color-white-dark;
  background: $color-white;
  margin-bottom: 1rem;
  min-height: 140px;
  max-height: 145px;
  transition: all .5s ease-out;

  &__recommended {
    padding: 2px 5px;
    color: $color-white;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    position: absolute;
    bottom: 6px;
    right: 6px;

    img {
      margin-right: 4px;
    }

    @include bgRecommendation();
  }

  &__inactive {
    @include inActive();
  }

  &__info-inactive {
    @include inActive();
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: .5rem 0;
    word-break: break-all;

    @include tabletScreen() {
      margin-left: -28px;
    }

    @include smallScreen() {
      margin-left: -28px;
    }

    img {
      width: 124px;
      height: 124px;

      @media (max-width: 280px) {
        width: 80px;
        height: 80px;
      }
    }

    div {
      width: 100%;
      align-content: center;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__price {
    font-weight: 600;
    color: $color-base-text;
  }

  &__display-discount {
    display: flex;
    flex-direction: row !important;

    span:first-of-type {
      font-size: 12px;
      color: $color-gray-shade;
      font-weight: 500;
      text-decoration: line-through;
    }

    span:last-of-type {
      margin-left: 2px;
      font-size: 12px;
      color: $color-base;
      font-weight: 500;
      text-decoration: none !important;
      letter-spacing: -0.2px;
      padding: 0px 8px;
      border-radius: 8px;
      background: $color-interpack-2;
    }
  }

  &__estimate {
    font-weight: 400;
    color: $color-text-small;
  }
}

::v-deep #modal-shipping-price {
  padding: 20px;
  border-radius: 12px;

  @include for-size(big-desktop) {
    width: 26vw;
  }

  @include for-size(desktop) {
    width: 35vw;
  }

  @include tabletScreen() {
    width: 50vw;
  }

  @include smallScreen() {
    width: 100%;
  }

  .modal-lp__content {
    &__close {
      position: absolute;
      top: 21px;
      right: 25px;
    }

    &__header {
      font-weight: 700;
      color: $color-base-text;
      border: none;
      margin-bottom: 20px;
    }

    &__body {
      padding: 0;
    }
  }
}

.shipping-product-title {
  justify-content: space-between;
  border-radius: 10px;

  &__ribbon {
    border-top-left-radius: 10px;
    width: 0;
    height: 0;
    border-width: 45px 45px 0 0;
    border-style: solid;
    position: relative;
  }

  &__ribbon-icon {
    text-align: center;
    color: $color-white;
    top: -41px;
    left: -54px;
    position: relative;
    width: 135px;
    margin: 0;

    > span {
      font-size: .65em;
      font-weight: bold;
      position: absolute;
      right: 52px;
      top: 3px;
      transform: rotate(-44deg);
    }

    img {
      width: 21px;
    }
  }

  &__question {
    cursor: pointer;

    @include for-size(mobile) {
      z-index: 1;
    }

    img {
      z-index: 50; // make quest mark clickable
      position: absolute;
      right: 10px;
      top: 10px;

      &:hover {
        ~ .shipping-product-title__question__desc {
          visibility: visible;
        }
      }
    }

    &__desc {
      visibility: hidden;
      display: flex;
      right: 10px;
      position: absolute;
      margin-top: -88px;
      border-radius: 5px;
      padding: 0 5px;
      width: 166px;
      text-align: center;
      height: 50px;
      background-color: $color-white;
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);

      &__text {
        text-align: center;
        margin: auto;
        font-size: 14px;
        font-weight: 400;
        color: $color-base-text;
      }
    }
  }
}

.promo-product {
  display: flex;
  color: $color-base-text;
  padding: 8px;
  border-radius: 8px;
  background: $color-gray-pensive;
  font-size: 12px;
  margin-bottom: 1rem;

  article {
    margin: 0 8px;
  }

  #promo-product-icon {
    display: flex;
    justify-content: flex-start;
    height: 100%;
  }

  #promo-product-info {
    cursor: pointer;
    transition: all .4s ease-in;

    &:hover {
      transform: scale(1.1);
    }
  }

  @include smallScreen() {
    font-size: 10px;
    letter-spacing: -0.2px;
  }
}
</style>
