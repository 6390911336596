<template>
  <modal
    id-custom="modal-product-promo"
    :use-icon-close="true"
    :header="true"
    :close-custom="true"
    @close="$emit('close')"
  >
    <template #header>
      <span>Jenis Pengiriman</span>
    </template>
    <template>
      <section style="margin-bottom: 20px;">
        <p class="title-into-product-promo">
          Harga ongkir tetap yang bisa kamu nikmati berdasarkan kategori berat
          paket
          <b>(tanpa perhitungan dimensi/volume)</b> & jenis pengiriman. Dengan
          rute pengiriman di area <b>JABODETABEKCIK</b>
        </p>
        <article
          v-for="(product, index) in productListPromo"
          :key="`pd-${index}`"
          class="info-promo-product"
        >
          <img
            :style="{marginLeft: product.name === 'REGPACK' ? '-1rem' : '-0.5rem'}"
            :src="product.img"
            alt="product icon"
          >
          <div
            v-for="(pr, j) in product.promo"
            :key="`pr-${index}-${j}`"
            class="info-promo-product__detail"
          >
            <span v-html="pr.title" />
            <span> <b>{{ pr.total }}</b> </span>
          </div>
        </article>
      </section>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/new-modal/Modal.vue';
import { ProductList } from '@/app/constant/product';

export default {
  name: 'ModalProductPromo',
  components: { Modal },
  computed: {
    productListPromo() {
      return ProductList;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/style';

::v-deep #modal-product-promo {
  border-radius: 12px;
  width: 600px;
  height: max-content;

  @include for-size(big-desktop) {
    width: 26vw;
  }

  @include for-size(desktop) {
    width: 35vw;
  }

  @include tabletScreen() {
    width: 50vw;
  }

  @include smallScreen() {
    width: 100%;
  }

  .modal-lp__content {
    &__close {
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
      padding: 20px !important;
    }

    &__header {
      border: none !important;
      padding: 20px 20px 0 20px !important;

      span {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &__body {
      padding: 0 !important;
    }
  }
}

.title-into-product-promo {
  padding: 0 12px 0 20px;
  color: $color-text-small;
  font-size: 14px;

  @include smallScreen() {
    font-size: 12px;
  }
}

.info-promo-product {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 12px;
  padding: 8px 16px;
  border: 1px solid $color-gray-pensive-3;
  background: $color-white;

  img {
    width: 100%;
    height: 100%;
    max-width: 130px;
    max-height: 130px;
    margin-top: -6px;
    margin-bottom: -1rem;
  }

  &__detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    padding: 6px 0;
    border-bottom: 1px solid $color-gray-pensive-3;

    &:last-child {
      border-bottom: none;
    }

    span b {
      font-weight: 600;
    }

    span:first-of-type {
      font-size: 12px;

      @include smallScreen() {
        font-size: 10px;
      }

      ::v-deep .product-size b {
        font-weight: 600;
      }
    }

    span:last-of-type {
      font-size: 14px;

      @include smallScreen() {
        font-size: 12px;
      }
    }
  }
}
</style>
