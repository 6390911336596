import ConsigneeFormRepository from "../api";
import '../type';

class ConsigneeFormUseCase {
  constructor() {
    /** @type {ConsigneeFormRepository} */
    this.api = new ConsigneeFormRepository();
  }

  /**
   *
   * @param id {string}
   * @returns {Promise<{data: null, err: *}|{data: ResponseSenderShipment, err: null}|{data: null, err: null}>}
   */
  async getSenderInfo(id) {
    return await this.api.getSenderInfo(id);
  }

  async sentRecipientData(payload) {
    return await this.api.sentRecipientData(payload);
  }
}

export default new ConsigneeFormUseCase();
