import CustomerAppAPI from '@/app/customerAppAxios';
import '../type';


export default class ConsigneeFormRepository extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/shipment/consignee-form';
  }

  /**
   *
   * @param id {string}
   * @returns {Promise<{data: null, err: *}|{data: ResponseSenderShipment, err: null}|{data: null, err: null}>}
   */
  async getSenderInfo(id) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}/${id}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err) };
    }
  }

  /**
   *
   * @param payload {PayloadReceiverShipment}
   * @returns {Promise<{data: null, err: *}|{data: Object, err: null}|{data: null, err: null}>}
   */
  async sentRecipientData(payload) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.post(`${this.endpoint}/response`, payload);
      if (res.status === 201 || res.status === 200) {
        return { data: 'success', err: null };
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err) };
    }
  }
}
