export const downloadApp = () => {
  const link = {
    android: 'https://play.google.com/store/apps/details?id=com.lionparcel.services.consumer&hl=in',
    ios: 'https://apps.apple.com/id/app/lion-parcel/id1455977134',
  };

  const isApple = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator?.userAgentData?.platform)
    || /iPad|iPhone|iPod/.test(navigator.userAgent)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  if (isApple) {
    window.open(link.ios);
  } else {
    window.open(link.android);
  }
}
