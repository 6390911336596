<template>
  <section
    :class="[
      { 'recipient-form-wrapper': (!errorGetSenderData || !isSuccessSubmit) },
    ]"
  >
    <template v-if="(errorGetSenderData || isSuccessSubmit) && !isShowIntroduction">
      <PageStatus
        :status="pageStatus.status"
        :title="pageStatus.title"
        :description="pageStatus.description"
        :button-text="pageStatus.buttonText"
      />
    </template>
    <template v-else>
      <div class="recipient-form-wrapper__banner">
        <image-lazy
          alt="Banner Form Penerima.webp"
          src="https://storage.googleapis.com/lionparcel-web-stg/banner/recipient-form.webp"
          @error="event => setErrorImage(event, 'banner')"
        />
      </div>

      <!-- ALERT -->
      <alert
        v-if="errorSubmit"
        :error-alert-message="errorSubmit"
      />
      <!-- START MODALS -->
      <ModalIntroduction
        v-if="isShowIntroduction"
        :sections="introSections"
        @done="finishIntroduction"
      />
      <ModalInfo
        v-if="showModal"
        @close="() => (showModal = false)"
      />
      <ModalShippingPrice
        v-if="showModalShippingPrice"
        :recommend="defaultProduct"
        :data="shippingPriceData"
        @close="() => (showModalShippingPrice = false)"
        @click-product="chooseProduct"
      />
      <ModalAction
        v-if="showModalConfirm"
        title="Yakin Kirim Detail Penerima?"
        button-primary="Ya, Kirim"
        button-secondary="Cek Ulang"
        desc="Pastikan detail yang kamu masukkan sudah sesuai untuk diproses pengirim"
        icon="confirm"
        @btn-primary="sendData"
        @btn-secondary="() => (showModalConfirm = false)"
        @close="() => (showModalConfirm = false)"
      />
      <ModalGoogleMap
        v-if="showModalMap"
        :last-coordinate="lastCoordinate"
        title="Pilih Lokasi Penerima"
        @close="() => (showModalMap = false)"
        @saved-coordinate="saveCoordinate"
      />
      <!-- END MODALS -->
      <section
        class="recipient-form-wrapper__form"
        :style="loadingGetSender ? { zIndex: '-1' } : ''"
      >
        <h1>Detail Pengirim</h1>
        <div class="sender_detail">
          <span class="sender_detail__icon">
            <img
              src="/images/ics_o_profile.svg"
              alt="profile lion parcel icon"
            >
          </span>
          <span class="sender_detail__card">
            <ribbon v-if="isContainsCOD" style="position: absolute; right: 15px;" :ribbon-name="ribbonCOD" />
            <span class="sender_detail__title"> Pengirim</span>
            <template v-if="!loadingGetSender && !isShowIntroduction">
              <span class="sender_detail__profile">
                {{ senderPackage.name }}, {{ senderPackage.phone }}</span>
              <span class="sender_detail__origin">
                {{ changeWordToTitle()(senderPackage.origin) }}</span>
              <span class="sender_detail__package-price">
                Harga barang ({{ senderPackagePrice }})</span>
            </template>
            <template v-else>
              <span
                class="sender_detail__profile skeleton-box"
                style="border-radius: 10px; width: 14rem; height: 20px;"
              />
              <span
                class="sender_detail__origin skeleton-box"
                style="border-radius: 10px; width: 14rem; height: 20px; margin: .5rem 0;"
              />
              <span
                class=" skeleton-box"
                style="border-radius: 10px; width: 14rem; height: 20px;"
              />
            </template>
          </span>
        </div>
        <h1>Isi Detail Penerima</h1>
        <Input
          v-model="recipient.name"
          class="input-text-recipient-form-component"
          name="recipient-name"
          label="Nama Penerima"
          placeholder="Masukan nama penerima"
          tabindex="0"
          :inject-other-class="validate.name !== null && validate.name !== '' ? 'error' : ''"
        />
        <ErrorInputMessage
          v-if="validate.name && validate.name !== ''"
          :message="validate.name"
          name="error-recipient-name"
        />
        <div class="input-recipient-field">
          <label for="recipient-phoneNumber">Nomor Handphone</label>
          <InputPhoneInternational
            v-model="recipient.phoneNumber"
            maxlength="17"
            tabindex="0"
            name="recipient-phoneNumber"
            :phone-code="recipientFormPhoneCode"
            :is-error="validate.phoneNumber !== null && validate.phoneNumber !== ''"
            @onPhoneCodeChange="onPhoneCodeChange"
          />
          <ErrorInputMessage
            v-if="validate.phoneNumber !== null && validate.phoneNumber !== ''"
            :message="validate.phoneNumber"
            name="error-recipient-phone"
          />
        </div>
        <div class="input-recipient-field">
          <label for="recipient-address">Alamat Lengkap</label>
          <Textarea
            id="custom-textarea"
            v-model="recipient.address"
            :custom-style="{'height': 'auto', 'min-height': '40px', padding: '10px 42px 10px 16px', overflow: 'hidden'}"
            :custom-class="validate.address !== null && validate.address !== '' ? 'error' : ''"
            :value="recipient.address"
            :rows="recipient.address.length > 0 ? 3 : 1"
            placeholder="Masukkan alamat lengkap"
            :label="undefined"
            icon-img="/images/ics_o_maps.svg"
            @click-icon="() => (showModalMap = true)"
          />
          <ErrorInputMessage
            v-if="validate.address !== null && validate.address !== ''"
            :message="validate.address"
            name="error-recipient-address"
          />
        </div>
        <div class="input-recipient-field" style="margin-top: 12px;">
          <Input
            v-model="recipient.referencePoint"
            inject-other-class="icon-patokan"
            name="recipient-referencePoint"
            placeholder="Tambah Patokan (opsional)"
            tabindex="0"
          />
        </div>
        <div class="input-recipient-field">
          <label for="recipient-destination">Kota/Kecamatan</label>
          <Dropdown
            v-model="recipient.destination"
            :options="routes"
            :value="recipient.destination"
            name="recipient-destination"
            placeholder="Pilih kecamatan/kota"
            :is-search="true"
            :is-loading="loadingSearch"
            :is-auto-filter="false"
            :empty-text="stateEmpty"
            :is-delete-on-selected="false"
            :is-error="validate.destination !== null && validate.destination !== ''"
            @input-search="handleInput"
            @input="onSelect"
          />
          <ErrorInputMessage
            v-if="validate.destination !== null && validate.destination !== ''"
            :message="validate.destination"
            name="error-recipient-destination"
          />
        </div>
        <div class="input-recipient-field">
          <label for="recipient-addresslabel">
            Label Alamat
          </label>
          <div style="display: flex;">
            <InputRadio
              id="rumah"
              name="recipient-addresslabel"
              input-value="rumah"
              label="Rumah"
              :checked="recipient.addressLabel === 'rumah'"
              @change="onRadioChange"
            />
            <InputRadio
              id="kantor"
              name="recipient-addresslabel"
              input-value="kantor"
              label="Kantor"
              :checked="recipient.addressLabel === 'kantor'"
              @change="onRadioChange"
            />
          </div>
        </div>
        <div class="recipient-productType">
          <label for="recipient-productType">Jenis Pengiriman</label>
          <div
            :class="[
              'recipient-select-product',
              {
                'recipient-select-product__not-available': !totalShipping.available,
              },
            ]"
            :style="
              totalShipping.price.display === 'Rp-' ? { cursor: 'not-allowed' } : {}
            "
            @click="chooseShippingPrice"
          >
            <div>
              <div class="recipient-select-product__name">
                <span v-if="!totalShipping.available">{{
                  totalShipping.product.name
                }}</span>
                <template v-else>
                  <div
                    v-if="loadingCheckPrice"
                    class="skeleton-box"
                    style="width: 4rem; height: 20px; border-radius: 10px;"
                  />
                  <img
                    v-else
                    :src="
                      `https://storage.googleapis.com/lionparcel-web-stg/asset/product/${totalShipping.product.name.toLowerCase()}.svg`
                    "
                    :alt="totalShipping.product.name"
                  >
                </template>
              </div>
              <div>
                <template v-if="!loadingCheckPrice">
                  <span>
                    {{ totalShipping.estimate }}
                    <img
                      src="https://storage.googleapis.com/lionparcel-web-stg/asset/product/checked.svg"
                      alt="icon estimated"
                    >
                  </span>
                  <span>{{ totalShipping.price.display }}</span>
                </template>
                <template v-else>
                  <div
                    class="skeleton-box"
                    style="width: 3rem; height: 20px; border-radius: 10px;"
                  />
                  <div
                    class="skeleton-box"
                    style="width: 4rem; height: 20px; border-radius: 10px;"
                  />
                </template>
                <img
                  width="18"
                  src="/images/chevron-right-gray.svg"
                  alt="select product"
                >
              </div>
            </div>
            <span class="recipient-select-product__title">
              <template v-if="loadingCheckPrice">
                <div
                  class="skeleton-box"
                  style="width: 12rem; height: 20px; border-radius: 10px;"
                />
              </template>
              <template v-else>
                <img
                  src="https://storage.googleapis.com/lionparcel-web-stg/asset/product/checked.svg"
                  alt="icon pasti"
                >
                {{ totalShipping.product.title }}
              </template>
            </span>
          </div>
        </div>
        <div class="recipient-insurance">
          <div>
            <CheckBox
              :disabled="isContainsCOD"
              :id="idCheckboxInsurance"
              tabindex="0"
              @click="onCheckedBox()"
            />
            <span>Asuransi</span>
          </div>
          <p>{{ insuranceText }}</p>
        </div>
        <div class="recipient-dropshipper">
          <div>
            <CheckBox
              :id="idCheckboxDropShipper"
              tabindex="0"
              @click="onCheckedBoxDropShipper()"
            />
          </div>
          <div>
            <span>Dropshiper</span>
            <img
              src="/images/ics_o_question_circle.svg"
              alt="checkbox"
              @click="() => (showModal = true)"
            >
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="recipient.isDropShipper"
            style="margin-top: 12px;"
          >
            <Input
              v-model="recipient.dropShipperName"
              class="input-text-recipient-form-component"
              :value="recipient.dropShipperName"
              name="recipient-dropshipper-name"
              label="Nama Dropshipper"
              placeholder="Masukan nama dropshipper"
              :inject-other-class="
                validate.dropShipperName && validate.dropShipperName !== '' ? 'error' : ''
              "
              tabindex="0"
            />
            <ErrorInputMessage
              v-if="validate.dropShipperName && validate.dropShipperName !== ''"
              :message="validate.dropShipperName"
              name="error-droppshipper-name"
            />
            <div style="margin-top: 12px" />
            <Input
              v-model="recipient.dropShipperPhoneNumber"
              class="input-text-recipient-form-component"
              :value="recipient.dropShipperPhoneNumber"
              name="dropshipper-phonenumber"
              maxlength="15"
              prefix="+62"
              tabindex="0"
              placeholder="Eg: 81367722112"
              :inject-other-class="
                validate.dropShipperPhoneNumber !== null &&
                  validate.dropShipperPhoneNumber !== '' ? 'error' : ''
              "
              label="Nomor Handphone"
            />
            <ErrorInputMessage
              v-if="
                validate.dropShipperPhoneNumber !== null &&
                  validate.dropShipperPhoneNumber !== ''"
              :message="validate.dropShipperPhoneNumber"
              name="error-droppshipper-phone"
            />
          </div>
        </transition>
        <Button
          :disabled="!noErrValidate"
          style="margin-top: 23px; padding: 12px 16px;"
          text="Kirim"
          @click="confirmData()"
        />
      </section>
    </template>
  </section>
</template>

<script>
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import Input from '@/components/new-input/Input.vue';
import CheckBox from '@/pages/new-claim/components/Checkbox/index.vue';
import InputRadio from '@/components/input-radio/InputRadio.vue';
import Button from '@/components/new-button/Button.vue';
import InputPhoneInternational from '@/components/input-phone-international/index.vue';
import ErrorInputMessage from '@/components/error-input-message/ErrorInputMessage.vue';
import Dropdown from '@/components/new-dropdown/Dropdown.vue';
import serviceRoute from '@/app/usecase/routeOriginAndDestination';
import serviceAlgoCheckPrice from '@/app/usecase/checkShippingPriceAlgo';
import serviceProduct from '@/app/usecase/product';
import Textarea from '@/components/new-textarea/Textarea.vue';
import { onCheckbox } from '@/pages/new-claim/app/utils';
import { convertRp } from '@/misc/currency';
import { changeWordToTitle } from '@/misc/stringMapper';
import PageStatus from '@/components/page-status/PageStatus.vue';
import ModalAction from '@/components/new-modal/ModalAction.vue';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import {
  RouteIndex, ProductSource, ProductName, ProductInfo,
} from '@/app/enum';
import Alert from '@/components/alert/Alert.vue';
import ModalGoogleMap from '@/components/new-modal/ModalGoogleMap.vue';
import ModalIntroduction from '@/components/new-modal/ModalIntroduction.vue';
import ModalShippingPrice from '../components/ModalShippingPrice.vue';
import ModalInfo from '../components/ModalInfo.vue';
import service from '../app/usecase';
import Ribbon from '../components/Ribbon.vue';
import {EnumCashOnDelivery} from "../../../app/enum";

export default {
  name: 'RecipientForm',
  components: {
    ImageLazy,
    Input,
    InputRadio,
    CheckBox,
    Button,
    InputPhoneInternational,
    Dropdown,
    Textarea,
    ModalInfo,
    ModalShippingPrice,
    ErrorInputMessage,
    PageStatus,
    ModalAction,
    Alert,
    ModalGoogleMap,
    ModalIntroduction,
    Ribbon,
  },
  mixins: [mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_recipient-form', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      introSections: [
        {
          img: '/images/Ill_spot_form_link.svg',
          title: 'Anti Repot Kirim Paket ke Banyak Alamat',
          desc: '<p> <b>Kamu pengirim?</b> Gak repot lagi isi banyak alamat & dapatkan spesial cashback untukmu </p>',
        },
        {
          img: '/images/Ill_spot_email_verifikasi_expired.svg',
          title: 'Makin Akurat Isi Alamatmu',
          desc: '<p> <b>Kamu penerima?</b> Gak usah takut salah alamat, bisa langsung isi alamatmu disini </p>',
        },
      ],
      disabledPhoneInternational: false,
      stateEmpty: 'Untuk Sementara Area Anda Belum Terjangkau Sehingga Tarif Tidak Tersedia',
      recipientFormPhoneCode: '+62',
      listHideIdentifier: [
        '#main-nav',
        '#app-router-view',
        '.new-main-footer',
        '.end-footer',
        '#wa-cs',
      ],
      senderPackage: {
        name: '',
        phone: '',
        origin: '',
        packagePrice: '',
        dimensionLength: 1,
        dimensionWidth: 1,
        dimensionHeight: 1,
        grossWeight: 1,
        isFragile: false,
        isCOD: false,
        isDFOD: false,
      },
      routes: [],
      idCheckboxInsurance: 'recipient-insurance',
      idCheckboxDropShipper: 'recipient-dropshipper',
      shippingPriceData: [
        {
          name: ProductName.BOSSPACK.toLowerCase(),
          displayStyle: {
            text_estimation: '-',
            root_service_asset: ProductSource.BOSSPACK.root,
            text_service_title: ProductInfo.BOSSPACK,
          },
          price: {
            'display': 'Rp-',
            value: 0,
          },
          priceBase: {
            'display': 'Rp-',
            value: 0,
          },
          discountPercentage: '',
          estimate: undefined,
          isChoose: true,
          isActive: false,
        },
        {
          name: ProductName.REGPACK.toLowerCase(),
          displayStyle: {
            text_estimation: '-',
            root_service_asset: ProductSource.REGPACK.root,
            text_service_title: ProductInfo.REGPACK,
          },
          price: {
            'display': 'Rp-',
            value: 0,
          },
          priceBase: {
            'display': 'Rp-',
            value: 0,
          },
          discountPercentage: '',
          estimate: undefined,
          isChoose: false,
          isActive: false,
        },
        {
          name: ProductName.JAGOPACK.toLowerCase(),
          displayStyle: {
            text_estimation: '-',
            root_service_asset: ProductSource.JAGOPACK.root,
            text_service_title: ProductInfo.JAGOPACK,
          },
          price: {
            'display': 'Rp-',
            value: 0,
          },
          priceBase: {
            'display': 'Rp-',
            value: 0,
          },
          discountPercentage: '',
          estimate: undefined,
          isChoose: false,
          isActive: false,
        },
        {
          name: ProductName.BIGPACK.toLowerCase(),
          displayStyle: {
            text_estimation: '-',
            root_service_asset: ProductSource.BIGPACK.root,
            text_service_title: ProductInfo.BIGPACK,
          },
          price: {
            'display': 'Rp-',
            value: 0,
          },
          priceBase: {
            'display': 'Rp-',
            value: 0,
          },
          discountPercentage: '',
          estimate: undefined,
          isChoose: false,
          isActive: false,
        },
        {
          name: ProductName.JUMBOPACK.toLowerCase(),
          displayStyle: {
            text_estimation: '-',
            root_service_asset: ProductSource.JUMBOPACK.root,
            text_service_title: ProductInfo.JUMBOPACK,
          },
          price: {
            'display': 'Rp-',
            value: 0,
          },
          priceBase: {
            'display': 'Rp-',
            value: 0,
          },
          discountPercentage: '',
          estimate: undefined,
          isChoose: true,
          isActive: false,
        },
        //uncomment jika mau tampilkan otopack
        // {
        //   name: ProductName.OTOPACK150.toLowerCase(),
        //   displayStyle: {
        //     text_estimation: '-',
        //     root_service_asset: ProductSource.OTOPACK150.root,
        //     text_service_title: ProductInfo.OTOPACK150,
        //   },
        //   price: {
        //     'display': 'Rp-',
        //     value: 0,
        //   },
        //   priceBase: {
        //     'display': 'Rp-',
        //     value: 0,
        //   },
        //   discountPercentage: '',
        //   estimate: undefined,
        //   isChoose: true,
        //   isActive: false,
        // },
        // {
        //   name: ProductName.OTOPACK250.toLowerCase(),
        //   displayStyle: {
        //     text_estimation: '-',
        //     root_service_asset: ProductSource.OTOPACK250.root,
        //     text_service_title: ProductInfo.OTOPACK250,
        //   },
        //   price: {
        //     'display': 'Rp-',
        //     value: 0,
        //   },
        //   priceBase: {
        //     'display': 'Rp-',
        //     value: 0,
        //   },
        //   discountPercentage: '',
        //   estimate: undefined,
        //   isChoose: true,
        //   isActive: false,
        // },
      ],
      defaultProduct: ProductName.BOSSPACK.toLowerCase(),
      productSort: {
        bosspack: {
          name: ProductName.BOSSPACK.toLowerCase(),
          index: 0,
        },
        regpack: {
          name: ProductName.REGPACK.toLowerCase(),
          index: 1,
        },
        jagopack: {
          name: ProductName.JAGOPACK.toLowerCase(),
          index: 2,
        },
        jumbopack: {
          name: ProductName.JUMBOPACK.toLowerCase(),
          index: 3,
        },
        bigpack: {
          name: ProductName.BIGPACK.toLowerCase(),
          index: 4,
        },
        //uncomment jika mau tampilkan otopack
        // otopack150: {
        //   name: ProductName.OTOPACK150.toLowerCase(),
        //   index: 5,
        // },
        // otopack250: {
        //   name: ProductName.OTOPACK250.toLowerCase(),
        //   index: 6,
        // },
      },
      debounce: {
        timeout: null,
        duration: 500,
      },
      totalShipping: {
        available: true,
        product: {
          name: ProductName.BOSSPACK.toLowerCase(),
          title: '-',
        },
        estimate: '-',
        price: {
          display: 'Rp-',
          value: 0,
        },
        estimateShippingPriceBase: undefined,
      },
      loadingSearch: false,
      loadingCheckPrice: false,
      loadingGetSender: false,
      errorGetSenderData: '',
      isSuccessSubmit: false,
      showModal: false,
      showModalShippingPrice: false,
      isShowIntroduction: false,
      keyLocalStorageIntro: 'intro-recipient-form',
      lastCoordinate: {},
      recipient: {
        addressLabel: 'rumah',
        isDropShipper: false,
        isInsurance: false,
        name: '',
        phoneNumber: '',
        destination: [],
        address: '',
        dropShipperName: '',
        dropShipperPhoneNumber: '',
        referencePoint: '',
      },
      insuranceText: 'Proteksi paket Anda dengan biaya 0.4% x Harga Barang',
      validateCode: {
        name: 'name',
        phoneNumber: 'phoneNumber',
        destination: 'destination',
        address: 'address',
        dropShipperName: 'dropShipperName',
        dropShipperPhoneNumber: 'dropShipperPhoneNumber',
      },
      errorMsg: {
        name: 'Nama penerima harus diisi',
        phoneNumber: 'Nomor handphone harus diisi',
        destination: 'Maaf, area tujuan belum tercakup',
        address: 'Alamat lengkap harus diisi',
        dropShipperName: 'Nama dropshipper harus diisi',
        dropShipperPhoneNumber: 'Nomor handphone dropshipper harus diisi',
      },
      validate: {
        name: null,
        phoneNumber: null,
        destination: null,
        address: null,
        dropShipperName: null,
        dropShipperPhoneNumber: null,
      },
      pageStatus: {
        status: 'success',
        title: 'Detailmu Berhasil Terkirim',
        description: 'Ayo! Download Aplikasi Lion Parcel sekarang untuk melacak paket kamu lebih mudah',
        buttonText: 'Download Aplikasi',
      },
      alreadyClickSend: false,
      showModalConfirm: false,
      showModalMap: false,
      errorSubmit: null,
      alertDuration: 5000,
    };
  },
  computed: {
    noErrValidate() {
      const errs = Object.values(this.validate);
      const errsKey = Object.keys(this.validate);

      return errs.every((val, index) => {
        if (!this.recipient.isDropShipper && (errsKey[index] === this.validateCode.dropShipperName
          || errsKey[index] === this.validateCode.dropShipperPhoneNumber)) {
          return true;
        }
        return val !== null && val === '' && this.recipient.destination.length > 0;
      });
    },
    isContainsCOD() {
      return this.senderPackage.isCOD
    },
    ribbonCOD() {
      return this.isContainsCOD && !this.senderPackage.isDFOD ? EnumCashOnDelivery.COD : EnumCashOnDelivery.DFOD
    },
    payloadRequestPrice() {
      const dest = this.recipient.destination;
      return {
        origin: this.senderPackage.origin,
        destination: dest.length > 0 ? dest[0].value : '',
        weight: this.senderPackage.grossWeight,
        page_type: 'check tariff',
        cod_value: 0,
        is_new_cod: false,
        goods_value: this.senderPackage.packagePrice,
        width: this.senderPackage.dimensionWidth,
        height: this.senderPackage.dimensionHeight,
        length: this.senderPackage.dimensionLength,
      };
    },
    senderPackagePrice() {
      return this.senderPackage.packagePrice
        ? `Rp${convertRp(this.senderPackage.packagePrice)}`
        : 'Rp-';
    },
  },
  watch: {
    'recipient.name': function () {
      this.validator(this.validateCode.name);
    },
    'recipient.phoneNumber': function () {
      this.validator(this.validateCode.phoneNumber);
    },
    'recipient.address': function () {
      this.validator(this.validateCode.address);
    },
    'recipient.dropShipperName': function () {
      this.validator(this.validateCode.dropShipperName);
    },
    'recipient.dropShipperPhoneNumber': function () {
      this.validator(this.validateCode.dropShipperPhoneNumber);
    },
  },
  mounted() {
    this.hideNavbarAndFooter();
  },
  async created() {
    await Promise.all([
      this.constructDataProducts(),
      this.getSenderDetail(),
      this.getAndSetDefaultRoutes(),
    ]);
  },
  beforeDestroy() {
    clearTimeout(this.debounce.timeout);
    clearTimeout(this.alertDuration);
  },
  methods: {
    validator(errorFor) {
      if (!errorFor.includes('drop')) {
        if (this.recipient[errorFor].length === 0) {
          let errMsg = this.errorMsg[errorFor];
          if (errorFor === this.validateCode.destination) {
            errMsg = 'Area tujuan harus diisi';
          }
          this.validate[errorFor] = errMsg;
        } else {
          this.validate[errorFor] = '';
        }
      } else if (this.recipient.isDropShipper && this.recipient[errorFor].length === 0) {
        this.validate[errorFor] = this.errorMsg[errorFor];
      } else {
        this.validate[errorFor] = '';
      }
    },
    sendData() {
      if (this.alertDuration) {
        clearTimeout(this.alertDuration);
      }

      /** @type {PayloadReceiverShipment} */
      const payload = {
        form_identifier: this.$route.params.id,
        recipient_name: this.recipient.name,
        recipient_phone_number: `${this.recipientFormPhoneCode}${this.recipient.phoneNumber}`,
        recipient_address: this.recipient.address,
        destination: this.recipient.destination[0].value,
        product_type: this.totalShipping.product.name.toUpperCase(),
        is_insurance: this.recipient.isInsurance,
        dropshipper_name: '',
        dropshipper_phone_number: '',
        label: this.recipient.addressLabel,
        estimate_shipping_price: this.totalShipping.price.value,
        estimate_shipping_price_base: this.totalShipping.estimateShippingPriceBase,
        reference_point: this.recipient.referencePoint,
      };

      if (this.recipient.isDropShipper) {
        payload.dropshipper_name = this.recipient.dropShipperName;
        payload.dropshipper_phone_number = `+62${this.recipient.dropShipperPhoneNumber}`;
      }

      if (typeof payload.estimate_shipping_price_base === 'undefined') {
        payload.estimate_shipping_price_base = this.totalShipping.price.value;
      }

      service
        .sentRecipientData(payload)
        .then((res) => {
          if (res.err !== null) {
            this.errorSubmit = res.err;
            if (res.err.toLowerCase().includes('consignee')) {
              this.pageStatus = {
                status: 'failed',
                title: 'Halaman Penerima Tidak Dapat Diakses',
                description: 'Silakan hubungi pengirim atau seller untuk meminta detail penerima terbaru',
                buttonText: undefined,
              };
              this.isSuccessSubmit = false;
            }
          } else {
            this.isSuccessSubmit = true;
          }
        })
        .catch(() => {
          this.errorSubmit = 'Maaf, terjadi kesalahan. Silahkan coba lagi';
        })
        .finally(() => {
          setTimeout(() => {
            this.errorSubmit = null;
          }, this.alertDuration);
        });
    },
    confirmData() {
      this.alreadyClickSend = true;

      if (this.noErrValidate) {
        this.showModalConfirm = true;
      }
    },
    changeWordToTitle() {
      return changeWordToTitle;
    },
    closeModal() {
      this.showModal = false;
    },
    chooseShippingPrice() {
      if (this.totalShipping.price.display === 'Rp-') {
        return;
      }
      this.showModalShippingPrice = true;
    },
    onCheckedBox() {
      this.recipient.isInsurance = !this.recipient.isInsurance;
      onCheckbox(
        !this.recipient.isInsurance ? 'remove' : 'add',
        this.idCheckboxInsurance,
      );
    },
    onCheckedBoxDropShipper() {
      this.recipient.isDropShipper = !this.recipient.isDropShipper;
      onCheckbox(
        !this.recipient.isDropShipper ? 'remove' : 'add',
        this.idCheckboxDropShipper,
      );
    },
    onSelect(val) {
      this.validate.destination = '';
      const shippingPriceDataDefault = this.shippingPriceData[this.productSort[this.defaultProduct].index];
      this.totalShipping.product.title = shippingPriceDataDefault.displayStyle.text_service_title || '-';
      this.totalShipping.product.name = this.defaultProduct;
      this.totalShipping.available = true;
      this.totalShipping.estimate = shippingPriceDataDefault.displayStyle?.text_estimation || shippingPriceDataDefault.estimate || '-';
      this.totalShipping.price.display = 'Rp-';
      this.resetProduct(true);

      if (!val || val.length === 0) {
        return;
      }

      this.loadingCheckPrice = true;

      serviceAlgoCheckPrice
        .requestShippingPrice(this.payloadRequestPrice)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            let isProductExist = false;
            for (const productDefaultKey in this.productSort) {
              if (!isProductExist) {
                this.totalShipping.product.name = productDefaultKey;
              }

              for (const productPrice of res.data) {
                if (this.productSort[productPrice.product.toLowerCase()]?.index) {
                  const productName = productPrice.product.toLowerCase();
                  const shippingPriceData = this.shippingPriceData[
                    this.productSort[productName]?.index
                    ];
                  const etd = productPrice.estimated_arrival_in_days;
                  const price = `Rp${convertRp(
                    Math.round(productPrice?.price),
                  )}`;
                  const priceBase = `Rp${convertRp(
                    Math.round(productPrice?.price_base),
                  )}`;

                  shippingPriceData.price = {
                    display: price,
                    value: productPrice?.price,
                  };
                  shippingPriceData.priceBase = {
                    display: priceBase,
                    value: productPrice?.price_base,
                  }
                  shippingPriceData.estimate = `${etd} hari`;
                  shippingPriceData.isActive = true;
                  shippingPriceData.discountPercentage = productPrice?.discount_percentage || '';
                  shippingPriceData.discountPercentage.trim()

                  const currentPrice = {
                    display: price,
                    value: productPrice?.price,
                  }

                  const getDiscount = shippingPriceData.discountPercentage && shippingPriceData.discountPercentage !== ''

                  if (getDiscount) {
                    currentPrice.display = priceBase;
                    currentPrice.value = productPrice?.price_base;
                  }

                  if (this.totalShipping.product.name === productName) {
                    this.totalShipping.product.title = shippingPriceData.displayStyle.text_service_title || '-';
                    shippingPriceData.isChoose = true;
                    isProductExist = true;
                    this.totalShipping.price.display = currentPrice.display;
                    this.totalShipping.price.value = currentPrice.value;
                    this.totalShipping.estimate = `${etd} hari`;
                  }

                  if (getDiscount && this.totalShipping.product.name === productName) {
                    this.totalShipping.estimateShippingPriceBase = productPrice?.price;
                  }
                }
              }
            }
          } else {
            this.validate.destination = this.errorMsg.destination;
            this.totalShipping.available = false;
          }
          this.loadingCheckPrice = false;
        })
        .catch(() => {
          this.validate.destination = this.errorMsg.destination;
          this.totalShipping.price.display = 'Rp-';
          this.totalShipping.price.value = 0;
          this.loadingCheckPrice = false;
          this.totalShipping.available = false;
        });
    },
    async getAndSetDefaultRoutes() {
      try {
        const res = await serviceRoute.getAllBySearch(
          RouteIndex.DESTINATION,
          '',
        );
        this.routes = this.makeDataRoutes(res);
        if (res.err) {
          this.stateEmpty = res.err;
        }
      } catch (err) {
        this.stateEmpty = err;
      }
    },
    makeDataRoutes(listDestination) {
      const routes = [];
      if (listDestination.data && listDestination.data.length > 0) {
        for (const destination of listDestination.data) {
          routes.push({
            label: destination.route_code,
            value: destination.route_code.toUpperCase(),
          });
        }
      }
      return routes;
    },
    handleInput(text) {
      this.loadingSearch = true;
      if (this.debounce.timeout) {
        clearTimeout(this.debounce.timeout);
      }

      this.debounce.timeout = setTimeout(async () => {
        try {
          const listDestination = await serviceRoute.getAllBySearch(
            RouteIndex.DESTINATION,
            text,
          );
          this.routes = this.makeDataRoutes(listDestination);
          if (listDestination.err) {
            this.stateEmpty = listDestination.err;
          }
        } catch (e) {
          this.stateEmpty = e;
        }
        this.loadingSearch = false;
      }, this.debounce.duration);
    },
    onPhoneCodeChange(phoneCode) {
      this.recipientFormPhoneCode = phoneCode;
    },
    hideNavbarAndFooter() {
      for (const uniqName of this.listHideIdentifier) {
        const el = document.querySelector(uniqName);
        if (el && uniqName !== '#app-router-view') {
          el.style.display = 'none';
        }
        if (el && uniqName === '#app-router-view') {
          el.style.padding = '0';
        }
      }
    },
    async constructDataProducts() {
      try {
        const res = await serviceProduct.getAll();
        if (res.data && res.data.length > 0) {
          for (const product of res.data) {
            const productName = product.service_name.toLowerCase();
            const productNameSort = this.productSort[productName];
            if (productNameSort && productNameSort.name === productName) {
              const defaultProduct = productNameSort.index === 0;
              product.text_estimation = product.text_estimation
                .toLowerCase()
                .replace('sampai', '');

              const shippingPriceData = this.shippingPriceData[this.productSort[productName].index];
              shippingPriceData.name = productName;
              shippingPriceData.displayStyle = product;
              shippingPriceData.isChoose = defaultProduct;
              shippingPriceData.isActive = false;

              if (defaultProduct) {
                this.totalShipping.estimate = product.text_estimation;
                this.totalShipping.product.title = product.text_service_title;
              }
            }
          }
        }
      } catch (err) {
        this.errorSubmit = 'Maaf, terjadi kesalahan mendapatkan data. Silahkan muat ulang halaman ini';
      } finally {
        setTimeout(() => {
          this.errorSubmit = null;
        }, this.alertDuration);
      }
    },
    onRadioChange(val) {
      this.recipient.addressLabel = val;
    },
    makeErrorPage() {
      this.pageStatus = {
        status: 'failed',
        title: 'Halaman Penerima Tidak Dapat Diakses',
        description: 'Silakan hubungi pengirim atau seller untuk meminta detail penerima terbaru',
        buttonText: undefined,
      };
    },
    resetProduct(isResetActive = false) {
      for (const product of this.shippingPriceData) {
        product.isChoose = false;
        if (isResetActive) {
          product.isActive = false;
        }
      }
    },
    chooseProduct(name, index) {
      this.showModalShippingPrice = false;
      this.totalShipping.product.name = name;
      this.totalShipping.product.title = this.shippingPriceData[index].displayStyle.text_service_title || '-';
      this.totalShipping.estimate = this.shippingPriceData[index].estimate || this.shippingPriceData[index].displayStyle.text_estimation;
      this.totalShipping.price.display = this.shippingPriceData[index].price.display;
      this.totalShipping.price.value = this.shippingPriceData[index].price.value;

      if (this.shippingPriceData[index].discountPercentage && this.shippingPriceData[index].discountPercentage !== '') {
        this.totalShipping.price.display = this.shippingPriceData[index].priceBase.display;
        this.totalShipping.price.value = this.shippingPriceData[index].priceBase.value;
        this.totalShipping.estimateShippingPriceBase = this.shippingPriceData[index].price.value;
      }
      // reset isChoose
      this.resetProduct();
      this.shippingPriceData[this.productSort[name].index].isChoose = true;
    },
    async getSenderDetail() {
      this.errorGetSenderData = false;
      this.loadingGetSender = true;

      try {
        const { data } = await service.getSenderInfo(
          this.$route.params.id,
        );
        this.loadingGetSender = false;
        if (data) {
          /** @type {ResponseSenderShipment} */
          const senderPackage = data;
          this.senderPackage = {
            name: senderPackage.sender_name,
            packagePrice: senderPackage.goods_value,
            phone: senderPackage.sender_phone_number,
            origin: senderPackage.origin,
            grossWeight: senderPackage.gross_weight,
            dimensionLength: senderPackage.dimension_length,
            dimensionHeight: senderPackage.dimension_height,
            dimensionWidth: senderPackage.dimension_width,
            isFragile: senderPackage.is_fragile,
            isCOD: senderPackage.is_cod,
            isDFOD: senderPackage.is_dfod,
          };
          if (!localStorage.getItem(this.keyLocalStorageIntro)) {
            this.isShowIntroduction = true;
          }
         if (this.senderPackage.isCOD && !this.senderPackage.isDFOD) {
           this.recipient.isInsurance = true;
           onCheckbox( 'add',
             this.idCheckboxInsurance,
           );
           this.insuranceText = 'Khusus COD, kamu telah dilindungi asuransi secara gratis'
         }
          if (this.senderPackage.isCOD && this.senderPackage.isDFOD) {
            this.recipient.isInsurance = false;
            onCheckbox( 'disabled-unchecked',
              this.idCheckboxInsurance,
            );
            this.insuranceText = 'Asuransi tidak aktif jika pilih COD Ongkir';
          }
        } else {
          this.loadingGetSender = false;
          this.errorGetSenderData = true;
          this.makeErrorPage();
        }
      } catch (err) {
        this.loadingGetSender = false;
        this.errorGetSenderData = true;
        this.makeErrorPage();
      }
    },
    setErrorImage(event, addClass) {
      this.isErrorImage = true;
      setImageDefault(event, addClass);
    },
    saveCoordinate(coordinate) {
      this.lastCoordinate = coordinate;
      this.recipient.address = coordinate.fullAddress;
      this.showModalMap = false;
    },
    finishIntroduction() {
      this.isShowIntroduction = false;
      localStorage.setItem(this.keyLocalStorageIntro, 'true');
    },
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
