<template>
  <Modal
    id-custom="modal-info-app"
    :use-icon-close="true"
    :header="true"
    :close-custom="true"
    @close="$emit('close')"
  >
    <template #header>
      <span>Dropshipper itu Apa Sih?</span>
    </template>
    <template>
      <p>Fitur ini membantu kamu mengirim barang atas nama customer lain sebagai pengirim</p>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/new-modal/Modal.vue';

export default {
  name: 'ModalInfo',
  components: { Modal },
};
</script>

<style scoped lang="scss">
::v-deep #modal-info-app {
  border-radius: 12px;
  padding: 20px;
  width: 600px;

  .modal-lp__content {
    &__close img {
      position: relative;
      top: 16px !important;
      right: 10px !important;
    }

    &__header {
      border: none;

      span {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &__body {
      padding: 0;

      p {
        font-size: 14px;
        width: 90%;
        margin-top: 16px;
      }
    }
  }
}
</style>
