<script>
import { EnumCashOnDelivery } from '../../../app/enum';
export default {
  name: 'RibbonRecipientForm',
  computed: {
    EnumCashOnDelivery() {
      return EnumCashOnDelivery;
    },
  },
  props: {
    ribbonName: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="ribbon">
    <svg
      v-if="ribbonName === EnumCashOnDelivery.DFOD"
      width="69"
      height="19"
      viewBox="0 0 69 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69 15C69 17.2091 67.2091 19 65 19L63.5932 19L63.5932 17.2C63.5932 16.0954 62.6978 15.2 61.5932 15.2L54.5 15.2L4.5 15.2C2.29086 15.2 0.5 13.4091 0.499999 11.2L0.499999 4.00001C0.499998 1.79087 2.29086 5.8319e-06 4.5 5.63877e-06L65 3.49691e-07C67.2091 1.56562e-07 69 1.79086 69 4L69 15Z"
        fill="url(#paint0_linear_4081_13879)"
      />
      <text
        x="3"
        y="11.2"
        font-family="Montserrat, sans-serif"
        font-weight="500"
        font-size="10.45"
        fill="white"
      >
        COD Ongkir
      </text>
      <defs>
        <linearGradient
          id="paint0_linear_4081_13879"
          x1="0.999995"
          y1="16.5"
          x2="38.1762"
          y2="2.63201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4899D" />
          <stop offset="1" stop-color="#B40727" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      v-if="ribbonName === EnumCashOnDelivery.COD"
      width="116"
      height="19"
      viewBox="0 0 116 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Rectangle 3325"
        d="M116 15C116 17.2091 114.209 19 112 19L110.593 19L110.593 17.2C110.593 16.0954 109.698 15.2 108.593 15.2L101.5 15.2L4.5 15.2C2.29086 15.2 0.5 13.4091 0.499999 11.2L0.499999 4.00001C0.499998 1.79087 2.29086 9.94077e-06 4.5 9.74764e-06L112 3.49691e-07C114.209 1.56562e-07 116 1.79086 116 4L116 15Z"
        fill="url(#paint0_linear_3945_44719)"
      />
      <text
        x="3"
        y="11.2"
        font-family="Montserrat, sans-serif"
        font-weight="500"
        font-size="10.35"
        fill="white"
      >
        COD Ongkir + Barang
      </text>
      <defs>
        <linearGradient
          id="paint0_linear_3945_44719"
          x1="-1.00001"
          y1="15"
          x2="41.6265"
          y2="-0.99524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4899D" />
          <stop offset="1" stop-color="#B40727" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
